import React from "react";
import { Routes, Route,  } from "react-router-dom";

import Login from "./pages/Login";
import Home from "./pages/Home";

function App(){

    return (
        <Routes>
            <Route index path='/' element={ <Home/> } />
            <Route path='/login' element={ <Login/> } />

            <Route path="*" element={ <Home/> }/>
        </Routes>
    );
}

export default App;