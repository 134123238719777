import {  } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#8D6E63",
      dark: "#3A2E39",
      contrastText: "#ffffff"
    },
    secondary: {
      main: "#8AA8A1",
      dark: "#D8DCFF",
      contrastText: "#000000"
    },
    error: {
      main: "#F23518",
      contrastText: "#000000"
    },
    warning: {
      main: "#FFCA3A",
      contrastText: "#000000"
    },
    info: {
      main: "#FCD29F",
      contrastText: "#ffffff"
    },
    success: {
      main: "#2E5339",
      dark: "#5C7457",
      contrastText: "#ffffff"
    }
  },
});

export default theme;