import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google'
import { 
    useEffect, 
    useState 
} from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import sha256 from 'crypto-js/sha256';
import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material'
import decodeUser from '../utils/userData'


function Login() {
    // const [showAlert, setShowAlert] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL


    var webText = {
        'es':{
            "login-title":"Iniciar sesión",
            "login-failed-alert":"¡Ups! Ha habido algún fallo con el inicio de sesión. Prueba a recargar la página o a buscar una red más estable."
        }
    }

    useEffect(() => {
        var user = Cookies.get('googleUserEncoded')
        if (user != null && Object.keys(user).length > 0) {
            navigate("/")
        }else{
            Cookies.remove('googleUserEncoded')
        }
    }, [navigate])

    function handleSuccessLogin(googleResponse) {

        setIsLoading(true)

        const credentials = googleResponse['credential']

        const email_hashed = sha256(decodeUser(credentials)['email'])

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 'email_hashed': email_hashed.toString() })
        }

        const url = `${apiBaseUrl}/users`

        fetch(url, requestOptions)
            .then((response) => {
                console.log(response.status)
                if (response.status === 409 || response.status === 200 || response.status === 201){
                    Cookies.set('googleUserEncoded', credentials, { expires:14 })
                    navigate('/')
                }
                setIsLoading(false)
            })

    }

    function handleFailedLogin() {
        // setShowAlert(true)
        Cookies.remove('googleUserEncoded')
    }

    return (
        
            <Container sx={{ 
                height:"100vh",
            }}>
                <Grid container sx={{
                    alignItems:"center",
                    height:"100%"
                }}>
                    <Container sx={{
                        justifyContent:"center",
                        textAlign:"center",
                        width:"max-content",
                    }}>
                        {
                            !isLoading ?
                            <Box>
                                <Typography variant='h4'>{webText["es"]["login-title"]}</Typography>
                                <Box
                                    sx={{ m:1 }}
                                >
                                    <GoogleOAuthProvider clientId="11957874629-r7v2eubikb2t1as00bu7ajap6b26ju05.apps.googleusercontent.com">
                                        <GoogleLogin
                                            onSuccess={handleSuccessLogin}
                                            onError={handleFailedLogin}
                                            size='large'
                                            auto_select
                                        />
                                    </GoogleOAuthProvider>
                                </Box>
                            </Box>
                            :
                            <CircularProgress/>

                        }
                    </Container>
                </Grid>
            </Container>
    )
}

export default Login

