
import { jwtDecode } from "jwt-decode";

function decodeUser(jwtUser){
    if (jwtUser != null && Object.keys(jwtUser).length > 0) {
            var user = jwtDecode(jwtUser)
            return {
                givenName: user['given_name'],
                familyName: user['family_name'],
                email: user['email'],
                fullName: user['name'],
                locale: user['locale'],
                picture: user['picture'],
                emailVerified: user['email_verified']
            }
    }

    return null
}

export default decodeUser