import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import sha256 from 'crypto-js/sha256';

import decodeUser from '../utils/userData';
import { 
    Avatar, 
    Box, 
    Container, 
    Grid, 
    Typography, 
    SpeedDial, 
    SpeedDialAction, 
    CardContent, 
    Card, 
    CardActions, 
    Button, 
    SvgIcon,
    CircularProgress,
    Icon,
    IconButton,
    CardMedia,
    Snackbar
} from "@mui/material";
import LanguageIcon from '@mui/icons-material/Language';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';

import dayjs from "dayjs";
import { ReactComponent as SpainIcon } from '../resources/esFlag.svg'
import { ReactComponent as USIcon } from '../resources/usFlag.svg'
import { ReactComponent as CataloniaIcon } from '../resources/caFlag.svg'



function Home() {

    const webText = {
        'spanish':{
            'nextEvents':'Siguientes eventos',
            'myGames': 'Mis juegos',
            'loading':'Cargando...',
            'spanish': 'Español',
            'english': 'Inglés',
            'catalan': 'Catalán',
            'buttonEventsGames': 'Juegos',
            'buttonGameDetails': 'Detalles',
            'dateFormat': 'DD/MM/YY HH:mm',
            'buttonCreate': 'Crear nuevo evento',
            'logOut':'Cerrar Sesión',
            'location':'Localización',
            'textNoEvents':'Actualmente no hay nuevos eventos, ¿quieres crear uno?',
            'textNoGames':'Actualmente no tienes juegos, ¿quieres añadir uno?',
            'buttonAddGame':'Añadir nuevo juego',
            'buttonAddEvent':'Crear nuevo evento',
            "login-title":"Iniciar sesión",
            "login-failed-alert":"¡Ups! Ha habido algún fallo con el inicio de sesión. Prueba a recargar la página o a buscar una red más estable.",
            "snackbar-language-change-error": "Error al cambiar de idioma"

        },
        'english':{
            "nextEvents": "Next events",
            "myGames": "My games",
            "loading": "Loading...",
            "spanish": "Spanish",
            "english": "English",
            "catalan": "Catalan",
            "buttonEventsGames": "Games",
            "buttonGameDetails": "Details",
            "dateFormat": "DD/MM/YY HH:mm",
            "buttonCreate": "Create new event",
            "logOut": "Log out",
            "location": "Location",
            "textNoEvents": "There are currently no new events, would you like to create one?",
            "textNoGames": "You currently have no games, would you like to add one?",
            "buttonAddGame": "Add new game",
            "buttonAddEvent": "Create new event"
        },
        'catalan':{
            "nextEvents": "Següents esdeveniments",
            "myGames": "Els meus jocs",
            "loading": "Carregant...",
            "spanish": "Espanyol",
            "english": "Anglès",
            "catalan": "Català",
            "buttonEventsGames": "Jocs",
            "buttonGameDetails": "Detalls",
            "dateFormat": "DD/MM/YY HH:mm",
            "buttonCreate": "Crear nou esdeveniment",
            "logOut": "Tancar sessió",
            "location": "Ubicació",
            "textNoEvents": "Actualment no hi ha nous esdeveniments, vols crear-ne un?",
            "textNoGames": "Actualment no tens jocs, vols afegir-ne un?",
            "buttonAddGame": "Afegir nou joc",
            "buttonAddEvent": "Crear nou esdeveniment"
        }        
    }

    const testEvents = [
        {
            "eventDatetime":"2024-02-10T17:30:00+01:00",
            "eventId":1,
            "title":"Evento 1",
            "participants": 5
        },
        {
            "eventDatetime":"2024-02-10 17:30:00+01:00",
            "eventId":1,
            "title":"Evento 2",
            "participants": 3
        },
    ]
    const testGames = [
        {
            "gameId":1,
            "title":"The Mind",
            "minPlayers": 2,
            "maxPlayers": 4,
            "image":"https://www.goblintrader.es/96011-superlarge_default/the-mind-spanish.jpg"
        },
        {
            "gameId":1,
            "title":"Catan",
            "minPlayers": 2,
            "maxPlayers": 4,
            "image":"https://devirinvestments.s3.eu-west-1.amazonaws.com/img/catalog/product/8436017220100-1200-face3d-copy.jpg"
        },
        {
            "gameId":1,
            "title":"Mini Rogue",
            "minPlayers": 1,
            "maxPlayers": 2,
            "image":"https://www.tablerum.com/sites/all/modules/ckeditor/ckfinder/userfiles/images/mini-rogue-pack-comprar-barato.jpg"
        },
    ]

    const [isLoading, setIsLoading] = useState(true)
    const [currentLanguage, setCurrentLanguage] = useState("spanish")
    const [showSnackbar, setShowSnackbar] = useState(false)

    const [userData, setUserData] = useState({
        givenName: '',
        familyName: '',
        email: '',
        fullName: '',
        locale: '',
        picture: '',
        emailVerified: false,
        emailHashed:''
    })
    const navigate = useNavigate()

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
    console.log(apiBaseUrl)

    const snackbar = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

    useEffect(() => {
        var userDecoded = decodeUser(Cookies.get('googleUserEncoded'))
        if (userDecoded != null){
            const emailHashed = sha256(userDecoded["email"]).toString()
            setUserData({
                ...userDecoded,
                emailHashed: emailHashed
            })
    
            fetch(`${apiBaseUrl}/users/${emailHashed}`, {method: "GET"})
                .then((response) => {
                    if (response.status === 200){
                        response.json().then((body) => {
                            setCurrentLanguage(body[0]["language_code"])
                            setIsLoading(false)
                        })
                    }
                })

            
        }
        else{
            navigate('/login')
        }
    }, [navigate, apiBaseUrl]);

    function changeLanguage(preferred_language){

        setIsLoading(true)

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                email_hashed: userData.emailHashed,
                preferred_language: preferred_language
            })
        }

        fetch(`${apiBaseUrl}/users`, requestOptions)
            .then((response) => {
                if (response.status !== 200){
                    setShowSnackbar(true)
                }else{
                    setCurrentLanguage(preferred_language)
                }
                setIsLoading(false)
            })
    }

    function handleClose(){
        setShowSnackbar(false)
    }

    if (isLoading) {
        return (
            <CircularProgress
                sx={{
                    position:"fixed",
                    top:"50%",
                    left:"50%",
                    transform:"translate(-50%, -50%)"
                }}
            />
        )
    }else{
        return (
            <Container>
                <Box sx={{ position: 'absolute', right:32, top:32 }}>
                    <SpeedDial
                        ariaLabel="language picker"
                        icon={<LanguageIcon/>}
                        direction="down"
                        hidden={false}
                    >
                        <SpeedDialAction
                            onClick={() => changeLanguage("spanish")}
                            icon={<SvgIcon component={SpainIcon} inheritViewBox/>}
                            tooltipTitle={webText[currentLanguage]["spanish"]}
                        />
                        <SpeedDialAction 
                            onClick={() => changeLanguage("english")}
                            icon={<SvgIcon component={USIcon} inheritViewBox/>}
                            tooltipTitle={webText[currentLanguage]["english"]}
                        />
                        <SpeedDialAction 
                            onClick={() => changeLanguage("catalan")}
                            icon={<SvgIcon component={CataloniaIcon} inheritViewBox/>}
                            tooltipTitle={webText[currentLanguage]["catalan"]}
                        />
                    </SpeedDial>
                </Box>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ p:5 }}
                >
                    <Box sx={{ m: 3 }}>
                        <Avatar 
                            alt={ userData.fullName }
                            src={ userData.picture }
                            sx={{ width: 112, height: 112 }}
                        />
                    </Box>
                    <Typography variant="h4" sx={{ textAlign: "center" }}>
                        {userData.fullName}
                    </Typography>
                    <Typography variant="subtitle">
                        {userData.email}
                    </Typography>
                    <Button 
                        sx={{ marginY:1 }}
                        onClick={() => {
                            Cookies.remove('googleUserEncoded')
                            navigate('/login')
                        }}
                    >
                        {webText[currentLanguage]["logOut"]}
                    </Button>
                </Grid>
                <Grid container sx={{justifyContent:"center", marginBottom:5}}>
                    <Box sx={{textAlign:"center"}}>
                        <Typography variant="h3" sx={{color:"primary.dark"}}>{webText[currentLanguage]["nextEvents"]}</Typography>
                    </Box>
                    <Grid container sx={{justifyContent:"center", marginY:1}}>
                        {
                            testEvents.length > 0 ?
                            testEvents.map((element) => 
                                (<Card sx={{ minWidth: 350, m:1 }}>
                                    <CardContent>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            {dayjs(element["eventDatetime"], 'YYYY-MM-DD HH:mm:ss.SSS Z', 'es', true)?.format(webText[currentLanguage]["dateFormat"])}
                                        </Typography>
                                        <Typography variant="h5" component="div">
                                            {element["title"]}
                                        </Typography>
                                        <Grid container sx={{justifyContent:"end"}}>
                                            <Icon sx={{ marginX: 1 }}>
                                                <PersonIcon/>
                                            </Icon>
                                            <Typography sx={{ fontSize:14, color:"text.secondary" }}>
                                                {element["participants"]}
                                            </Typography>
                                        </Grid>
                                    </CardContent>
                                    <CardActions sx={{ justifyContent:"space-between" }}>
                                        <Button 
                                            startIcon={<LocationOnIcon/>}
                                            onClick={() => window.open("https://www.google.com", "_blank")}
                                        >
                                            {webText[currentLanguage]["location"]}
                                        </Button>
                                        <Button variant="outlined" size="small">{webText[currentLanguage]["buttonEventsGames"]}</Button>
                                    </CardActions>
                                </Card>)
                            )
                            :
                            <Typography sx={{color:"error.main"}}>{webText[currentLanguage]["textNoEvents"]}</Typography>
                        }
                    </Grid>
                    <Button 
                        variant="contained" 
                        size="large" 
                        startIcon={<AddIcon/>}
                        sx={{
                            marginY:3
                        }}
                    >
                        {webText[currentLanguage]["buttonAddEvent"]}
                    </Button>
                </Grid>
                <Grid container sx={{justifyContent:"center", marginY:5 }}>
                    <Box sx={{textAlign:"center"}}>
                        <Typography variant="h3" sx={{color:"primary.dark"}}>{webText[currentLanguage]["myGames"]}</Typography>
                    </Box>
                    <Grid container sx={{justifyContent:"center", marginY:1}}>
                        {
                            testGames.length > 0 ?
                            testGames.map((element) => 
                                (<Card sx={{ minWidth: 350, m:1 }}>
                                    <CardMedia
                                        sx={{height:200}}
                                        image={element["image"]}
                                    />
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            {element["title"]}
                                        </Typography>
                                        <Grid container sx={{justifyContent:"end"}}>
                                            <Icon sx={{ marginX: 1 }}>
                                                <PersonIcon/>
                                            </Icon>
                                            <Typography sx={{ fontSize:14, color:"text.secondary"}}>
                                                {
                                                    "".concat(element.minPlayers.toString()).concat(' - ').concat(element.maxPlayers.toString())
                                                }
                                            </Typography>
                                        </Grid>
                                    </CardContent>
                                    <CardActions sx={{ justifyContent:"end" }}>
                                        <Button variant="outlined" size="small">{webText[currentLanguage]["buttonGameDetails"]}</Button>
                                    </CardActions>
                                </Card>)
                            )
                            :
                            <Typography sx={{color:"error.main"}}>
                                {webText[currentLanguage]["textNoGames"]}
                            </Typography>
                        }
                    </Grid>
                    <Button 
                            variant="contained" 
                            size="large" 
                            startIcon={<AddIcon/>}
                            sx={{
                                marginY:3
                            }}
                        >
                            {webText[currentLanguage]["buttonAddGame"]}
                        </Button>
                </Grid>
                <Snackbar
                    open={showSnackbar}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message={webText[currentLanguage]['snackbar-language-change-error']}
                    action={snackbar}
                />
            </Container>
        )
    }
}

export default Home;